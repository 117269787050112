<template>
  <div class="box">
     <router-link
      v-if="$can('admin/coupon/send-create')"
      :to="{ path: '/send-coupon/create' }"
    >
      <el-button
        type="primary"
        size="medium"
      >
        新发优惠券
      </el-button>

    </router-link>

     <div class="box-toolbar">
      <div class="box-item">
        <el-input
          placeholder="请输入名称"
          v-model="search_name"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      height="67vh"
      :stripe="true"
      :data="lists">
      <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column>
      <el-table-column
        width="300"
        label="营销活动名"
        prop="send_name"
      >
      </el-table-column>
      <el-table-column
        width="120"
        label="开始发放日期"
        prop="des"
      >
      </el-table-column>
      <el-table-column
        label="发送状态"
        prop="status"
      >
       <template slot-scope="scope">
        <span v-if="scope.row.status == '0'" style="color:#F56C6C">已关闭</span>
        <span v-if="scope.row.status == '1'" style="color:#409EFF">运行中</span>
      </template>
      </el-table-column>
      <el-table-column
        label="发放数量"
        width="160"
        prop="send_sum"
      >

      </el-table-column>

      <el-table-column
        label="已发放数量"
        width="160"
        prop="sum"
      >

      </el-table-column>

      <el-table-column
        fixed="right"
        width="190"
        label="操作"
      >
        <template slot-scope="scope">

           <el-button
            plain
            size="mini"
            :type="scope.row.status == 1?'danger':'primary'"
            @click="toggle(scope.row)"
          >
            {{scope.row.status == 1?'禁用':'启用'}}
          </el-button>
          <el-dropdown
            trigger="click"
            style="margin-left:10px;"
          >
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin/msg/update')"
                @click.native="handleModelEdit(scope.row)"
              >
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin/msg/delete')"
                @click.native="handleModelDelete(scope.row)"
              >
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="shop-i-bottom">
      <page
        class="shop-page"
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
    </div>
  </div>
</template>

<script>
import CouponService from '@admin/services/CouponService'
import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  name: 'SendCoupon',
  data () {
    return {
      search_name: '',
      function: function (param) {
        return CouponService.allSend(param)
      },
      delete: function (param) {
        return CouponService.sendDelete(param)
      }
    }
  },

  components: {
    Page
  },
  mixins: [pagination],
  methods: {
    async searchGo () {
      this.page = 1
      let param = {
        send_name: this.search_name
      }
      this.where = param
      await this.getList(param)
    },
    AddMsg () {
      this.$router.replace({ path: '/send-coupon/create' })
    },
    handleModelEdit (model) {
      this.$router.push({ path: '/send-coupon/update', query: { id: model.id } })
    },
    async toggle (row) {
      let param = {
        toggle: true,
        ...row
      }
      const { data } = await flatry(CouponService.toggle(row.id, param))
      if (data) {
        this.$message.success(data.msg)
        this.searchGo()
      }
    }
  }

}

</script>
<style lang='scss' scoped>
.shop-i-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .shop-page {
        float: right;
        margin: 0;
    }
}

</style>
